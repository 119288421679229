<template>
    <b-modal title="Order items" v-model="orderModalActive" no-close-on-backdrop scrollable>

        <template #default>
            <draggable
                v-model="localCopy"
                tag="ul"
                class="list-group list-group-flush cursor-move">

                <b-list-group-item
                    v-for="(item, index) in localCopy"
                    :key="index"
                    tag="li"
                >
                    <strong>{{index + 1}} -  {{ item.name }}</strong>
                </b-list-group-item>

            </draggable>
        </template>

        <template #modal-footer>

            <b-button variant="danger" @click="orderModalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="warning" @click="saveOrder">
                <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                />
                <span class="align-middle">Save</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>

    import {BButton, BListGroupItem, BModal} from 'bootstrap-vue'
    import draggable from 'vuedraggable'

    export default {
        components: {
            BButton,
            BModal,
            BListGroupItem,
            draggable
        },
        props: {
            value: {
                type: Array,
                required: true,
                default: () => []
            },
            saveURL: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                orderModalActive: false,
                localCopy: []
            }
        },
        methods: {
            open() {
                this.orderModalActive = true
            },
            saveOrder() {
                const thisIns = this
                const loadPromise = this.$http.patch(this.saveURL, this.localCopy.map(category => category.id))
                loadPromise.then(function() {
                    thisIns.$printSuccess('Order saved')
                    thisIns.orderModalActive = false
                    thisIns.$emit('orderSaved')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            }
        },
        watch: {
            value() {
                this.localCopy = JSON.parse(JSON.stringify(this.value))
            }
        }
    }

</script>