<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Categories">

                <b-button variant="primary" class="mr-1" @click="addModalActive = true">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <b-button variant="primary" @click="$refs.orderModal.open()">
                    <fa icon="sort" class="mr-50 align-middle"/>
                    <span class="align-middle">Order categories</span>
                </b-button>

                <basic-table :columns="columns" :data="categories" v-slot="props">
                    <div v-if="props.column.displayType === 2" class="d-flex justify-content-center">
                        <b-button variant="warning" @click="openCategoryEdit(props.row)" class="mr-1">
                            <feather-icon
                                icon="EditIcon"
                            />
                        </b-button>
                        <b-button variant="danger" @click="removeCategory(props.row.id)">
                            <feather-icon
                                icon="Trash2Icon"
                            />
                        </b-button>
                    </div>
                    <span v-else-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-badge v-if="props.row.fully_translated" variant="light-success">
                            Yes
                        </b-badge>
                        <b-badge v-else variant="light-danger">
                            No
                        </b-badge>
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>

        <b-modal title="Add new" v-model="addModalActive" no-close-on-backdrop>
            <template #default>
                <b-form-group>
                    <label for="name">Name:</label>
                    <b-form-input
                        id="name"
                        type="text"
                        placeholder="Name"
                        v-model="addObject.name"
                    />
                </b-form-group>
            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="addModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="addCategory">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </template>
        </b-modal>

        <b-modal title="Edit category" size="lg" v-model="editModalActive" no-close-on-backdrop>
            <template #default>
                <b-form-group>
                    <label for="name">Name:</label>
                    <b-form-input
                        type="text"
                        placeholder="Name"
                        v-model="editObject.name"
                    />
                </b-form-group>

                <hr/>

                <Translations class="mt-3" :base-url="`${  baseRoute}/${  editObject.id}`" :fields="[{name: 'Name', field: 'name', type: 0}]" v-on:translationsChanged="loadData"/>
            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="editModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="warning" @click="editCategory">
                    <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Save</span>
                </b-button>
            </template>
        </b-modal>

        <OrderModal ref="orderModal" :value="categories" :save-u-r-l="this.baseRoute + '/set_order'" v-on:orderSaved="loadData"/>
    </div>
</template>
<script>

    import { BOverlay, BCard, BButton, BModal, BFormInput, BFormGroup, BBadge } from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import Translations from '@/views/Translation/Translations'
    import OrderModal from '@/views/components/OrderModal'
    export default {
        components: {
            OrderModal,
            Translations,
            BasicTable,
            BOverlay,
            BCard,
            BButton,
            BModal,
            BFormInput,
            BFormGroup,
            BBadge
        },
        props: {
            baseItemId: {
                type: String,
                required: false,
                default: ''
            },
            customRoute: {
                type: String,
                required: false,
                default: null
            }
        },
        data() {
            return {
                categories: [],
                dataLoaded: false,

                baseRoute: '',

                columns: [
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Name'
                        }
                    },
                    {
                        label: 'Fully translated',
                        displayType: 1,
                        field(rowObj) {
                            return (rowObj.fully_translated) ? 'Yes' : 'No'
                        },
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Yes / No'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 2,
                        field: 'actions',
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                pageLength: 10,

                addObject: {},
                addModalActive: false,

                editObject: {},
                editModalActive: false
            }
        },
        methods: {
            openCategoryEdit(category) {
                this.editObject = JSON.parse(JSON.stringify(category))
                this.editModalActive = true
            },
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get(this.baseRoute)
                loadPromise.then(function(response) {
                    thisIns.categories = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addCategory() {
                const thisIns = this
                const loadPromise = this.$http.post(this.baseRoute, this.addObject)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Category added')
                    thisIns.addModalActive = false
                    thisIns.$set(thisIns.addObject, 'name', '')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            editCategory() {
                const thisIns = this
                const loadPromise = this.$http.put(`${this.baseRoute  }/${  this.editObject.id}`, this.editObject)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Category saved')
                    thisIns.editModalActive = false
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            removeCategory(id) {
                const thisIns = this
                const loadPromise = this.$http.delete(`${  this.baseRoute}/${  id}`)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Category removed')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            }
        },
        created() {
            this.baseRoute = (this.customRoute) ? this.customRoute : `/api/management/v1/base_item/${  this.baseItemId}/categories`
        },
        mounted() {
            this.loadData()
        }
    }
</script>
